<template>
    <div class="common-layout">
        <el-container class="h-100" style="border: 0px">
            <el-aside class="bg-white" :style="{ width: aside_width + 'px' }">
                <div class="flex-c flex-jc-sb h-100">
                    <div class="flex-c flex-jc-c h-px-60 text-center c-white" style="background-color: #183770">
                        <h2 v-if="aside_width == 300">{{ userinfo?.brand_name || mate_name }}</h2>
                        <h2 v-else>{{ userinfo?.brand_name?.substr(0, 1) || '聚' }}</h2>
                    </div>
                    <div class="flex-1 menu-left-box" style="background-color: #132d62; border-top: 0px">
                        <aw-menu
                            :default_active="path_active"
                            :menu_items="menu_items"
                            :menu_info="{ background_color: '#132d62', text_color: '#ffffff' }"
                            :menu_action="menu_action"
                        />
                    </div>
                </div>
            </el-aside>
            <el-container>
                <el-header style="background-color: #183770; border-bottom: 0px; border-left: 0px">
                    <aw-menu-header :menu_items="menu_header" :menu_info="{ background_color: '#183770', text_color: '#ffffff' }">
                        <template #collapse>
                            <div @click="isCollapseEvent(menu_action.isCollapse)" class="c-p">
                                <el-icon class="v-a-middle c-white" :size="24">
                                    <component :is="menu_action.isCollapse ? 'Expand' : 'Fold'"></component>
                                </el-icon>
                            </div>
                        </template>
                        <template #start>
                            <span class="c-white" style="margin-top: 5px">{{ userinfo.username }}</span>
                        </template>
                    </aw-menu-header>
                </el-header>
                <el-main style="background-color: #e9eef3">
                    <div class="p-12">
                        <aw-pageheader :menu_info="page_header" class="m-b-12" />
                        <router-view />
                    </div>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import { http, dict } from '@/assets/js'
export default {
    name: 'index',
    data() {
        return {
            menu_items: JSON.parse(localStorage.getItem('mymodels')),
            menu_header: [
                {
                    index: '',
                    icon: 'SwitchButton',
                    name: '退出',
                    path: '/',
                    action: (_this) => {
                        _this.$router.push({ path: '/' }).catch((error) => error)
                        localStorage.clear()
                        console.log('退出登录,清空token')
                    }
                }
            ],
            menu_action: { isCollapse: false },
            path_active: '',
            page_header: [],
            windowWidth: window.innerWidth,
            aside_width: '300',
            mate_name: dict.platform.mate_name,
            userinfo: JSON.parse(localStorage.getItem('userinfo')) //获取用户信息
        }
    },
    watch: {
        // 监听路由变化
        $route(to) {
            this.getRoute(to.path, to)
        },
        windowWidth(newWidth) {
            if (newWidth <= 900) {
                this.menu_action.isCollapse = true
                this.aside_width = '64'
            } else {
                this.menu_action.isCollapse = false
                this.aside_width = '300'
            }
        }
    },
    mounted() {
        this.getRoute(this.$route.path, this.$route)
        this.path_active = this.$route.path
        window.addEventListener('resize', this.handleResize)
    },
    // 在组件即将卸载之前执行的清理操作
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        getRoute(path, to) {
            this.page_header = to.matched
                .filter((n) => n.path != '/index')
                .map((n) => {
                    return {
                        icon: n.meta.icon,
                        name: n.meta.name.split(' | ')[0],
                        path: n.path
                    }
                })
        },
        isCollapseEvent(isCollapse) {
            this.menu_action.isCollapse = isCollapse ? false : true
            this.aside_width = this.menu_action.isCollapse ? '64' : '300'
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        }
    }
}
</script>
<style scoped>
.common-layout {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.el-main {
    padding: 0;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--el-border-color-lighter);
}

.el-aside {
    transition: width 1s;
    -webkit-transition: width 1s; /* Safari */
}

.el-aside:not(.el-menu--collapse) {
    width: 300px;
    min-height: 500px;
}

.el-header {
    padding: 0;
    border-left: 1px solid var(--el-border-color-lighter);
    border-bottom: 1px solid var(--el-border-color-lighter);
}

.menu-left-box {
    overflow: auto;
    border-top: 1px solid var(--el-border-color-lighter);
    border-bottom: 1px solid var(--el-border-color-lighter);
}
</style>
